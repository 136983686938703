<template>
  <v-card light>
    <v-container fluid v-if="!loading && team.registrationData">
      <v-row dense>
        <v-col cols="12">
          <v-chip color="success white--text" class="mr-1">
            Order: {{team.registrationData.orderId}} {{team.registrationData.items > 1 ? ` +${team.registrationData.items - 1}` : ''}}
            <v-btn
              color="white"
              small
              icon
              :to="`/order/details/${team.registrationData.orderId}`"
            >
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
          </v-chip>
          <confirmation-email
            :team="team"
          ></confirmation-email>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" :lg="players.length < 3 ? 6 : 4" v-for="(p, i) in players" :key="`${p.i}-${i}`">
          <v-card height="100%">
            <v-toolbar color="color2 color2Text--text" flat>
              <v-toolbar-title>
                Player {{i + 1 }}&nbsp;<span class="error--text">{{ p.deleted ? ' (Deleted)' : '' }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="color1 color1Text--text" fab small @click.stop="edit(p)" v-if="user && user.vbl">
                <v-icon>fas fa-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="caption grey--text text-center">Player Profile Id: {{p.i}} | Registration Player Id: {{p.rpId}}</div>
            <template v-if="hasAgreements">
              <v-card-text>
                <v-chip
                  v-for="(a, i) in tournament.getPlayerAgreements(p.i, division.id)"
                  :key="`${p.i}-${division.id}-${i}`"
                  :color="a.status === 'Signed' ? 'success' : 'error'"
                >
                    <v-icon class="mr-1" x-small>
                      fas fa-signature
                    </v-icon>
                    <div class="text-truncate">
                      {{a.name}} {{a.status}}
                    </div>
                    <player-agreement-dialog
                      :playerProfileId="p.i"
                      :agreementId="a.id"
                      :agreement="a.name"
                      color="white"
                    ></player-agreement-dialog>
                </v-chip>
              </v-card-text>
            </template>
            <v-list dense>

              <v-list-item v-for="(field, n) in p.fields" :key="`${i}-${n}`">
                <v-list-item-content>{{ field.label }}:</v-list-item-content>
                <v-list-item-content class="align-end" v-if="field.label.toLowerCase().includes('phone')">{{ field.value | usPhone }}</v-list-item-content>
                <v-list-item-content class="align-end" v-else>{{ field.value }}</v-list-item-content>
              </v-list-item>

            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" v-if="addInfo && addInfo.length > 0">
          <v-card height="100%">
            <v-toolbar color="color2 color2Text--text" flat>
              <v-toolbar-title>
                Additional Info
              </v-toolbar-title>
            </v-toolbar>
            <div class="caption grey--text text-center">&nbsp;</div>
            <v-list dense>

              <v-list-item v-for="field in addInfo" :key="field.label">
                <v-list-item-content>{{ field.label }}:</v-list-item-content>
                <v-list-item-content class="align-end" v-if="field.label.toLowerCase().includes('phone')"><a :href="`tel:+${field.value}`">{{ field.value | usPhone }}</a></v-list-item-content>
                <v-list-item-content class="align-end" v-else>{{ field.value }}</v-list-item-content>
              </v-list-item>

            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
        transition="dialog-transition"
        scrollable
      >
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Player Edit</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color1 color1Text--text" fab small @click.stop="dialog=false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="selectedPlayer">
            <div class="title">{{selectedPlayer.name}}</div>
            <v-window v-model="window" touchless>

              <v-window-item :value="0">
                <v-btn color="color3" text block @click.stop="window = 1">Create new profile</v-btn>
                <v-btn color="color3" text block @click.stop="window = 2">Edit Reg Field</v-btn>
              </v-window-item>
              <v-window-item :value="1">
                <div class="subheading py-4">
                  Create a new player profile with this registration information?
                </div>
                <div class="text-center">
                  <v-btn
                    color="color3 color3Text--text"
                    @click.stop="create"
                    :loading="iLoading"
                  >Yes</v-btn>
                </div>
              </v-window-item>
              <v-window-item :value="2">
                <div class="subheading py-4">
                  Select the registration field you would like to edit
                </div>
                <v-autocomplete
                  ref="select"
                  :items="selectedPlayer.fields"
                  item-text="label"
                  return-object
                  v-model="selectedField"
                ></v-autocomplete>
                <v-expand-transition>
                  <v-text-field
                    ref="newField"
                    v-if="selectedField"
                    :label="`New ${selectedField.label}*`"
                    v-model="newFieldValue"
                  ></v-text-field>
                </v-expand-transition>
                <div class="text-center">
                  <v-btn
                    color="color3 color3Text--text"
                    @click.stop="save"
                    :loading="iLoading"
                    :disabled="!(newFieldValue && selectedField && newFieldValue !== selectedField.value)"
                  >Save</v-btn>
                </div>
              </v-window-item>

            </v-window>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions style="min-height: 56px">
            <v-fab-transition>
              <v-btn
                color="color3Text color3--text"
                fab
                v-if="window > 0"
                @click.stop="window = 0"
                small
              >
                <v-icon>fas fa-caret-left</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex'
const PlayerAgreementDialog = () => import('@/components/Agreements/PlayerAgreementDialog')
const ConfirmationEmail = () => import('@/components/Team/ConfirmationEmail.vue')

export default {
  props: ['team', 'loading', 'tournament', 'division'],
  data () {
    return {
      dialog: false,
      selectedPlayer: null,
      window: 0,
      iLoading: false,
      newProfileId: null,
      selectedField: null,
      newFieldValue: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    regData () {
      if (!this.loading && this.team && this.team.registrationData && this.team.registrationData.players) {
        return {
          players: this.team.registrationData.players.filter(f => !f.team),
          team: this.team.registrationData.players.find(f => f.team)
        }
      }

      return false
    },
    players () {
      return this.regData && this.regData.players ? this.regData.players.map(p => {
        const obj = {}
        Object.assign(obj, p)
        const player = this.team.players.find(f => f.playerProfileId === p.i)
        obj.deleted = !player
        obj.player = player
        return obj
      }) : []
    },
    addInfo () {
      return this.regData && this.regData.team ? this.regData.team.fields : false
    },
    hasAgreements () {
      return this.tournament.agreementStatuses.length && this.division
    }
  },
  methods: {
    edit (player) {
      this.selectedPlayer = player
      this.dialog = true
    },
    create () {
      if (!this.selectedPlayer) return
      this.iLoading = true
      this.$VBL.team.createNewPlayer(this.selectedPlayer.rpId)
        .then(r => {
          this.team.registrationData = r.data
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.iLoading = false })
    },
    save () {
      if (!(this.selectedPlayer && this.selectedField && this.newFieldValue)) return
      const dto = { field: this.selectedField.label, value: this.newFieldValue }
      this.iLoading = true
      this.$VBL.team.savePlayerField(this.selectedPlayer.rpId, dto)
        .then(r => {
          this.selectedField.value = this.newFieldValue
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.iLoading = false })
    },
    reset () {
      this.dialog = false
      this.selectedPlayer = null
      this.window = 0
      this.newProfileId = null
      this.selectedField = null
      this.newFieldValue = null
    }
  },
  watch: {
    selectedField: function (v) {
      this.newFieldValue = v ? v.value : null
      v && this.$nextTick(() => {
        this.$refs && this.$refs.select && this.$refs.select.blur()
        this.$refs && this.$refs.newField && this.$refs.newField.focus()
      })
    },
    dialog: function (v) {
      !v && this.reset()
    }
  },
  components: {
    PlayerAgreementDialog,
    ConfirmationEmail
  },
  mounted () {
    this.tournament.getAgreementStatus()
  }
}
</script>

<style>

</style>
